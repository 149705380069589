<template>
  <div class="vm-section vm-articles">
    <v-container class="main-container">
      <h1 class="faq-title">How can we help?</h1>
      <p class="faq-subtitle mt-3">
        Search our knowledge base for instant answers to your questions.
      </p>
      <div class="input-area">
        <v-text-field
          label="Type keywords to find answers"
          color="#ff008e"
          class="search-field"
          single-line
          clearable
          append-icon="mdi-magnify"
          v-model="searchInput"
          @input="searchQuestions"
        ></v-text-field>

        <v-btn
          v-on:click="packageBtn"
          rounded
          dark
          class="btn-package reach-products"
          id="order"
          v-if="!show"
        >
          See available packages
        </v-btn>
        <div v-if="!show" class="address-inner black--text">
          <v-icon class="black--text ">mdi-map-marker</v-icon>
          <router-link
            to="/"
            class="font-weight-500 black--text move-to"
            id="top-coverage"
            data-section="vm-coverage-wrap"
          >
            {{ house_address }}
          </router-link>
        </div>
        <div v-else class="text no-address">
          <NoAddress></NoAddress>
        </div>
      </div>
      <div class="vm-faqs-wrap" v-if="searchInput == ''">
        <v-tabs v-model="tab" dark grow centered fixed-tabs class="header-tabs">
          <v-tab
            v-for="(faq, f) in entries"
            :key="faq.product_type"
            class="vm-faq-tab"
            ><span class="products-text">{{ products[f] }}</span></v-tab
          >
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(faq, f) in entries" :key="faq.product_type">
            <v-container class="faq-container">
              <v-tabs vertical class="d-none d-md-flex side-tabs">
                <v-tab v-for="(item, i) in faq.entries" :key="item.title">{{
                  item.title
                }}</v-tab>

                <v-tab-item
                  v-for="(item, i) in faq.entries"
                  class="faq-vertical-content"
                  :key="item.title"
                >
                  <v-container>
                    <v-expansion-panels>
                      <v-expansion-panel
                        v-for="(item, i) in item.contents"
                        :key="i"
                      >
                        <v-expansion-panel-header>
                          {{ item.question }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content :id="item.question">
                          <div v-html="item.answer"></div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-container>
                </v-tab-item>
              </v-tabs>
              <template>
                <v-expansion-panels class="d-flex d-md-none">
                  <v-expansion-panel v-for="(item, i) in faq.entries" :key="i">
                    <v-expansion-panel-header>{{
                      item.title
                    }}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-expansion-panels>
                        <v-expansion-panel
                          v-for="(item, i) in item.contents"
                          :key="i"
                        >
                          <v-expansion-panel-header v-html="item.question">
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <div v-html="item.answer"></div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <div class="search-keyword-wrap" v-else>
        <h1 class="search-keyword-header">Showing search results for</h1>
        <p class="search-keyword-subheader">{{ userKeywords }}</p>
        <v-expansion-panels>
          <v-expansion-panel v-for="(item, i) in searchResults" :key="i">
            <v-expansion-panel-header>
              {{ item.question }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-html="item.answer"></div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-container>
  </div>
</template>

<script>
  import NoAddress from '@/components/Faq/NoAddress'

  export default {
    name: 'FAQs',
    components: {
      NoAddress
    },
    data: () => ({
      faqs: [],
      panel: [0],
      tab: null,
      tabs: null,
      entries: [],
      products: ['Vuma Fibre Core', 'Vuma Fibre Reach'],
      show: false,
      searchInput: '',
      userKeywords: '',
      searchResults: [],
      house_address: '',
      product_type: ''
    }),
    created() {
      const data = require('../../../faq.json')
      this.entries = data.data
      this.house_address = $cookies.get('user_address')
      this.product_type = $cookies.get('product_type')

      if (this.house_address === null) {
        this.show = true
      } else {
        this.tab = this.product_type === 'VUMACORE' ? 0 : 1
      }
    },
    mounted() {
      $('.move-to').on('click', function(e) {
        e.preventDefault()

        let target = '#' + $(this).data('section')
        let $target = $(target)

        $('html, body')
          .stop()
          .animate(
            {
              scrollTop: $target.offset().top - 70
            },
            900,
            'swing'
          )
      })
    },
    methods: {
      findTitle(terms, faq) {
        terms.some(function(term) {
          const index = faq.title.toLowerCase().indexOf(term)
          return index > -1
        })
      },
      searchQuestions(input) {
        this.searchResults = []
        this.searchInput = (!input) ? '' : input
        const searchTerms = this.searchInput.match(/\w+/g)
        if (searchTerms) {
          this.userKeywords = searchTerms.join(', ')
          for (const section of this.entries[0].entries) {
            for (const faq of section.contents) {
              if (
                searchTerms.some(function(term) {
                  return (
                    faq.question.toLowerCase().indexOf(term.toLowerCase()) >
                      -1 ||
                    faq.answer.toLowerCase().indexOf(term.toLowerCase()) > -1
                  )
                })
              ) {
                faq.answer.replace(
                  searchTerms[0],
                  `<mark>${searchTerms[0]}</mark>`
                )
                this.searchResults.push(faq)
              }
            }
          }
        }
      },
      packageBtn(){
        if(this.product_type === 'VUMACORE'){
          this.$router.push('/omniscript')
        }
        if(this.product_type === 'VUMAREACH'){
          this.$router.push('/product/reach')
        }
      }
    }
  }
</script>

<style lang="scss">
  h1 {
    font-size: 64px;
    font-family: 'Core Sans R 25', 'Roboto', sans-serif !important;
    font-weight: inherit;
  }
  .search-field {
    max-width: 550px;
    line-height: 45px;
    max-height: 45px;
    font-size: 4rem;
    margin: 16px 16px 16px 0px;
  }
  .search-keyword-header {
    text-align: center;
  }
  .search-keyword-subheader {
    color: #ee008c;
    font-size: 2rem;
    text-align: center;
    font-weight: bold;
  }
  .input-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header-tabs {
    width: 100%;
  }
  .no-address {
    margin: 0 auto;
  }
  .v-expansion-panel-header {
    font-size: 1.3rem;
    line-height: 1.6rem;
    font-weight: bold;
  }
  .btn-package {
    margin: 16px;
  }
  .address-inner {
    display: flex;
    margin: 16px;
  }
  .side-tabs {
    min-width: 400px;
  }
  .address {
    display: flex;
    justify-content: space-between;
  }
  .vm-faqs-wrap {
    margin-top: 3rem;

    .theme--dark.v-tabs {
      & > .v-tabs-bar {
        background-color: #ee008c;
        height: 85px;
      }

      .v-tabs-slider-wrapper {
        display: none;
      }
    }

    .vm-faq-tab.v-tab {
      font-size: 25px;
      font-family: 'Core Sans R 35', 'Roboto', sans-serif !important;
      text-transform: none;

      letter-spacing: 0.1em;
      width: 20%;
      color: #fff !important;

      &.v-tab--active {
        font-family: 'Core Sans R 55', 'Roboto', sans-serif !important;

        &::before {
          opacity: 0.24 !important;
        }

        &:after {
          content: '';
          display: block;
          width: 0;
          height: 0;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          border-top: 20px solid #ee008c;
          position: absolute;
          bottom: -20px;
          left: 50%;
        }
      }

      &:hover::before {
        opacity: 0.24;
      }
    }

    .faq-container {
      padding: 3rem;
    }

    .v-tabs--vertical {
      .v-tab {
        text-align: left;
        text-transform: none;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: start;
        height: auto;
        padding-top: 1rem;
        padding-bottom: 1rem;
        min-width: 360px;
        color: #3f3f3f !important;
        font-size: 26px;
        border-bottom: 1px solid #eee;

        &.v-tab--active {
          color: #ff008e;
          white-space: pre-wrap;
          font-family: 'Core Sans R 45';
        }
      }

      .v-tabs-slider-wrapper {
        color: #ff008e !important;
        caret-color: #ff008e !important;
      }

      > .v-tabs-bar {
        .v-tabs-bar__content {
          white-space: pre-wrap;
        }
      }

      .faq-vertical-content {
        .v-card {
          padding-left: 2rem;
          padding-right: 2rem;

          .v-card__text {
            color: #3f3f3f !important;
            font-size: 20px;
            line-height: 1.2;

            ul {
              margin-bottom: 1rem;
              padding-left: 2rem;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 960px) {
    .search-field {
      width: 360px;
      padding: 16px;
    }
    .search-keyword-subheader {
      font-size: 1.2rem;
    }
    .products-text {
      white-space: nowrap;
      font-size: 16px;
    }
    .main-container {
      padding: 0px;
    }
    .faq-title {
      text-align: center;
      font-size: 45px;
    }
    .faq-subtitle {
      text-align: center;
      padding: 8px;
    }
    .search-keyword-header {
      text-align: center;
      font-size: 28px;
      white-space: nowrap;
      padding: 16px;
    }
    .btn-package {
      margin: 32px auto 32px auto;
    }
    .address-inner {
      display: flex;
      margin: 16px 0px 0px;
    }
    .input-area {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .v-tabs--vertical {
      display: block;
    }
    .vm-faqs-wrap .v-tabs--vertical .v-tab {
      display: block;
      max-width: 100%;
    }
    .vm-faqs-wrap .v-tabs--vertical .v-slide-group__wrapper {
      display: block;
    }
    .vm-faqs-wrap .v-tabs--vertical .faq-vertical-content .v-card {
      padding-left: 0;
      padding-right: 0;
      margin-top: 2rem;
    }
    .vm-faqs-wrap .faq-container {
      padding: 0;
    }
    .v-expansion-panel-header {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
    .vm-faqs-wrap .vm-faq-tab.v-tab {
      font-size: 20px;
    }
    .vm-faqs-wrap .theme--dark.v-tabs > .v-tabs-bar {
      height: 60px;
    }
    .v-expansion-panel-content__wrap {
      font-size: 17px;
    }
  }
  @media screen and (max-width: 360px) {
    .faq-title {
      font-size: 40px;
      font-weight: 500;
    }
    .search-field {
      width: 300px;
      padding: 0px;
      margin: 0 auto 16px;
    }
    .products-text {
      font-size: 13px;
    }
    .search-keyword-header {
      font-size: 20px;
      white-space: nowrap;
      padding: 8px;
    }
    .btn-package {
      margin: 16px auto 16px auto;
    }
  }
</style>
