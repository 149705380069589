<template>
  <v-container>
    <div>
      <router-link
        to="/"
        class="font-weight-500 text move-to"
        id="top-coverage"
        data-section="vm-coverage-wrap"
      >
        Please enter your address to confirm your product (Check your coverage)
      </router-link>
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'NoAddress',
    mounted() {
      $('.move-to').on('click', function(e) {
        e.preventDefault()

        let target = '#' + $(this).data('section')
        let $target = $(target)

        $('html, body')
          .stop()
          .animate(
            {
              scrollTop: $target.offset().top - 70
            },
            900,
            'swing'
          )
      })
    },
    methods: {
      scrollCoverage() {
        let $target = $('.vm-coverage-wrap')

        $('html, body')
          .stop()
          .animate(
            {
              scrollTop: $target.offset().top - 50
            },
            500,
            'swing'
          )
      }
    }
  }
</script>

<style scoped>
  .text {
    color: #ff008e;
  }
</style>
